// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-comenzar-js": () => import("./../../../src/pages/comenzar.js" /* webpackChunkName: "component---src-pages-comenzar-js" */),
  "component---src-pages-empresas-old-js": () => import("./../../../src/pages/empresas_old.js" /* webpackChunkName: "component---src-pages-empresas-old-js" */),
  "component---src-pages-hubspot-success-js": () => import("./../../../src/pages/hubspot-success.js" /* webpackChunkName: "component---src-pages-hubspot-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacidad-js": () => import("./../../../src/pages/privacidad.js" /* webpackChunkName: "component---src-pages-privacidad-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terminos-y-condiciones-js": () => import("./../../../src/pages/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-js" */),
  "component---src-templates-confirmation-error-js": () => import("./../../../src/templates/confirmation-error.js" /* webpackChunkName: "component---src-templates-confirmation-error-js" */),
  "component---src-templates-confirmation-success-js": () => import("./../../../src/templates/confirmation-success.js" /* webpackChunkName: "component---src-templates-confirmation-success-js" */),
  "component---src-templates-password-recovery-js": () => import("./../../../src/templates/password-recovery.js" /* webpackChunkName: "component---src-templates-password-recovery-js" */),
  "component---src-templates-password-success-js": () => import("./../../../src/templates/password-success.js" /* webpackChunkName: "component---src-templates-password-success-js" */)
}

